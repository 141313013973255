(function() {
    'use strict';

    angular
        .module('app.core')
        .factory('$dimsum', $dimsum);

    /* @ngInject */
    function $dimsum() {

        var
        classic = "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        cicero_1_10_32 = "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
        cicero_1_10_33 = "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.",
        jabberwocky = "Twas brillig, and the slithy toves Did gyre and gimble in the wabe; All mimsy were the borogoves, And the mome raths outgrabe. Beware the Jabberwock, my son! The jaws that bite, the claws that catch! Beware the Jubjub bird, and shun The frumious Bandersnatch! He took his vorpal sword in hand: Long time the manxome foe he sought So rested he by the Tumtum tree, And stood awhile in thought. And as in uffish thought he stood, The Jabberwock, with eyes of flame, Came whiffling through the tulgey wood, And burbled as it came! One, two! One, two! and through and through The vorpal blade went snicker-snack! He left it dead, and with its head He went galumphing back. And hast thou slain the Jabberwock? Come to my arms, my beamish boy! O frabjous day! Callooh! Callay! He chortled in his joy. 'Twas brillig, and the slithy toves Did gyre and gimble in the wabe; All mimsy were the borogoves, And the mome raths outgrabe.",

        defaults = {
            format: 'text',
            flavor: 'latin',
            sentences_per_paragraph: [3, 5],
            words_per_sentence: [10, 31],
            commas_per_sentence: [0, 4],
            use_sentence_case: true,
            strip_punctuation: true
        },

        punct = [',','.',';',':','?','!','"','—','\r','\n'],
        punct_reg = new RegExp('[' + punct.join('') + ']*','g'),
        end_punct_reg = /[.?!"]/g,
        mid_punct_reg = /[,;:-]/g,

        // conf = Object.assign({}, defaults),
        conf = shallow_copy({}, defaults),

        flavors = {
            'latin': dedupe( normify([classic, cicero_1_10_32, cicero_1_10_33]).split(' ') ),
            'jabberwocky': dedupe( normify([jabberwocky]).split(' ') )
        };

        var dimsum = {
            initialize: initialize,
            generate: generate,
            sentence: sentence,
            paragraph: paragraph
        };

        return dimsum;
        //////////////

        function configure(options) {
            conf = shallow_copy(conf, options);
            return conf;
        }

        function initialize() {
            return configure(defaults);
        }

        function generate(num_paragraphs, options) {
            var config = conf,
                sentences = [],
                paragraphs = [],
                result = '',
                num_paragraphs = num_paragraphs || 1;
            if(typeof num_paragraphs == 'object') {
                options = num_paragraphs;
                num_paragraphs = 1;
            }
            configure(options);
            while(paragraphs.length < num_paragraphs) {
                paragraphs.push(paragraph());
            }
            switch(conf.format) {
                case 'text':
                    result = paragraphs.join("\r\n\r\n");
                    break;
                case 'html':
                    result = '<p>' + paragraphs.join('</p><p>') + '</p>';
                    break;
            }
            configure(config);
            return result;
        }

        // Create a single sentence of random lipsum.
        function sentence() {
            // console.log(['sentence', this, dimsum, $dimsum]);
            var word = '',
                words = [],
                num_words = range(conf.words_per_sentence[0], conf.words_per_sentence[1]),
                num_commas = range(conf.commas_per_sentence[0], conf.commas_per_sentence[1]),
                flavor = flavors[conf.flavor];

            while(words.length < num_words) {
                word = flavor[ range(0, flavor.length -1) ];
                words.push(word);
            }

            for(var i = 0; i < num_commas; i++) {
                word = range(4, words.length - 3);
                if(!words[word]) {
                    break;
                }
                else if(words[word].match(',')) {
                    i--;
                }
                else {
                    words[word] += ',';
                }
            }

            // Capitalize the first word
            words = words.join(' ');
            words = words.replace(/^[a-z]/i, words[0].toUpperCase());

            // Punctuate and return
            if(words[words.length - 1].match(end_punct_reg)) {
                return words;
            }
            if(words[words.length - 1].match(mid_punct_reg)) {
                return words.slice(0, words.length - 1) + '.';
            }
            return words + '.';
        }

        // Create a single paragraph of random lipsum.
        function paragraph() {
            var sentences = [],
                num_sentences = range(conf.sentences_per_paragraph[0], conf.sentences_per_paragraph[1]);
            while(sentences.length < num_sentences) {
                sentences.push(sentence());
            }
            return sentences.join(' ');
        }

        /** Utils **/
        function dedupe(array) {
            var obj = {},
                result = [];
            for(var i = 0; i < array.length; i++) {
                obj[array[i]] = null;
            }
            for(var key in obj) {
                result.push(key);
            }
            return result;
        }

        function normify(strings) {
            var result = strings.join(' ').replace(/\r|\n/, ' ');
            if(conf.use_sentence_case === true) {
                result = result.toLowerCase();
            }
            if(conf.strip_punctuation === true) {
                result = result.replace(punct_reg, '');
            }
            return result;
        }

        function range(min, max) {
            return min + Math.random() * (max - min) << 0;
        }

        function shallow_copy() {
            var i, key, result = {},
                args = Array.prototype.slice.call(arguments);
            for(i = 0; i < args.length; i++) {
                for(key in args[i]) {
                    result[key] = args[i][key];
                }
            }
            return result;
        }

    }

// 'title': dimsum.sentence().split(/[\s\t\,\.\-]+/, 4 + Math.floor(Math.random() * 4)).join(' '),
// 'shortdesc': dimsum(1, { format: 'html' }),
// 'description': dimsum(Math.round(Math.random() * 5), { format: 'html' })

})();