(function() {
    'use strict';

    angular
        .module('app.core')
        .run(run);

    /* @ngInject */
    function run($rootScope, $window, $location) {

        $rootScope.win = {
            width: $window.innerWidth,
            height: $window.innerHeight,
            orientation: ($window.innerWidth / $window.innerHeight >= 1) ? 'landscape' : 'portrait'
        };

        $rootScope.rsp = {
            vpn : {
                min: 0,
                max: 479,
                chk: false
            },
            hpn : {
                min: 480,
                max: 767,
                chk: false
            },
            apn : {
                min: 0,
                max: 767,
                chk: false
            },
            vpd : {
                min: 768,
                max: 895,
                chk: false
            },
            hpd : {
                min: 896,
                max: 1024,
                chk: false
            },
            apd : {
                min: 768,
                max: 1024,
                chk: false
            },
            mbl : {
                min: 0,
                max: 1024,
                chk: false
            },
            ltp : {
                min: 1025,
                max: 1199,
                chk: false
            },
            dtp : {
                min: 1200,
                max: 1919,
                chk: false
            },
            fhd : {
                min: 1920,
                max: 9999,
                chk: false
            }
        };

        angular.forEach($rootScope.rsp, function(rso, rsk) {
            if (rso.min == 0) {
                rso.chk = (rso.max >= $window.innerWidth);
            } else if (rso.max == 9999) {
                rso.chk = (rso.min <= $window.innerWidth);
            } else {
                rso.chk = !!(rso.min <= $window.innerWidth && rso.max >= $window.innerWidth);
            }
        }, true);

        $rootScope.getWinSize = function () {
            return {
                width: $window.innerWidth,
                height: $window.innerHeight
            };
        };

        $rootScope.$watch($rootScope.getWinSize, function(wv) {
            $rootScope.win = {
                width: wv.width, height: wv.height,
                orientation: (wv.width / wv.height >= 1) ? 'landscape' : 'portrait'
            };
            angular.forEach($rootScope.rsp, function(rso, rsk) {
                if(rso.min == 0) {
                    rso.chk = (rso.max >= wv.width);
                } else if(rso.max == 9999) {
                    rso.chk = (rso.min <= wv.width);
                } else {
                    rso.chk = !!(rso.min <= wv.width && rso.max >= wv.width);
                }
            }, true);
        }, true);
        // win.bind('resize', function () { $scope.$apply(); });

    }

})();