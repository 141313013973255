(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('product', product);

    /* @ngInject */
    function product() {

        var directive = {
            bindToController: true,
            templateUrl: 'product.tpl',
            controller: productController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                prod: '<'
            }
        };

        return directive;
        /////////////////
        
        function link(scope, element, attrs) {
        }

    }

    /* @ngInject */
    function productController() {
        console.log(['productController', this]);
    }

})();