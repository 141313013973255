(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('slider', slider);

    /* @ngInject */
    function slider() {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            templateUrl: 'slider.tpl',
            controller: sliderController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                slides: '<'
            }
        };
        return directive;
        /////////////////
        
        function link(scope, element, attrs) {
            // console.log(['slider.link', scope]);
            // scope.currentSlide = 0;
        }

    }

    /* @ngInject */
    function sliderController() {
        var vm = this;
        vm.currentSlide = 0;
        // console.log(['slider.controller', vm]);
        vm.nextSlide = function() {
            vm.currentSlide++;
            if(vm.currentSlide >= vm.slides.length) vm.currentSlide = 0;
        };
        vm.prevSlide = function() {
            vm.currentSlide--;
            if(vm.currentSlide < 0) vm.currentSlide = vm.slides.length - 1;
        };
    }

})();