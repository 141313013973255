(function() {
    'use strict';

    angular
        .module('app.layout')
        .component('banner', {
            templateUrl: 'banner.tpl',
            controller: bannerController,
            // controllerAs: '$ctrl'
        });

    /* @ngInject */
    function bannerController($rootScope) {
        // var $ctrl = this;
    }

})();