(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('routeCtrl', routeCtrl);

    /* @ngInject */
    function routeCtrl(
        $scope, $rootScope, $location, $routeParams, $window,
        $animate, $timeout, $sce, $http, $api, $data, json
    ) {

        $scope.data = json.data;
        if($scope.data.stats && $scope.data.stats.page_title) {
            // console.log(['page_title', $scope.data.stats.page_title]);
            document.title = $scope.data.stats.page_title;
            // $rootScope.title = $scope.data.stats.page_title;
        }
        if($scope.data.stats.is_home) {
            $scope.data.slides = [
                {
                    title: 'Slide 01',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                },
                {
                    title: 'Slide 02',
                    text: 'Etiam molestie augue in turpis imperdiet, ac rhoncus erat congue.'
                },
                {
                    title: 'Slide 03',
                    text: 'Quisque bibendum leo at viverra rutrum.'
                }
            ];
        }

        $scope.selectTemplate = function() {
            if(
                $scope.data.stats.is_home
            ) {
                return 'home';
            }
            // if(
            //     $scope.data.stats.is_single &&
            //     $scope.data.stats.post_type	== 'animal'
            // ) return 'single_animal';
            if(
                $scope.data.stats.is_single ||
                $scope.data.stats.is_singular ||
                $scope.data.stats.is_page
            ) return 'single';
            // if(
            //     $scope.data.stats.is_archive &&
            //     $scope.data.stats.is_post_type_archive &&
            //     $scope.data.stats.post_type	== 'animal'
            // ) return 'archive_animal';
            if(
                // $scope.data.stats.is_archive &&
                $scope.data.stats.is_tax
            ) return 'tax';
            // if(
            //     $scope.data.stats.is_archive &&
            //     $scope.data.stats.is_post_type_archive
            // ) return 'pt_archive';
            if(
                $scope.data.stats.is_archive
            ) return 'archive';
            if(
                $scope.data.stats.is_404
            ) return '404';
            return;
        };

    }

})();
