(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('rating', rating);

    
    function rating() {
        var directive = {
            bindToController: true,
            templateUrl: 'rating.tpl',
            controller: ratingController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                rating: '=val'
            }
        };
        return directive;
        
        function link(scope, element, attrs) {
        }

    }

    /* @ngInject */
    function ratingController() {
        var vm = this;
        vm.round = function() {
            return Math.round(vm.rating);
        }
    }

})();