(function() {
    'use strict';

    angular
        .module('app.layout')
        .component('menu', {
            templateUrl: 'menu.tpl',
            controller: menuController,
            controllerAs: '$ctrl',
            bindings: {
                menu: '<',
            },
        })
        .run(run);

    /* @ngInject */
    function menuController($rootScope) {
        var $ctrl = this;

        $ctrl.isActive = function(url) {
            // console.log([$rootScope.curl, url]);
            return ($rootScope.curl == url);
        }
        // $ctrl.onInit = function() { };
        // $ctrl.onChanges = function(changesObj) { };
        // $ctrl.onDestory = function() { };
    }

    /* @ngInject */
    function run($rootScope, $http, $location) {
        $rootScope.menu = {};
        $http.get('/wp-json/wp-api-menus/v2/menu-locations/headl').then(function(rs) {
            $rootScope.menu.headl = rs.data;
        });
        $http.get('/wp-json/wp-api-menus/v2/menu-locations/headr').then(function(rs) {
            $rootScope.menu.headr = rs.data;
        });
        $rootScope.$on('$routeChangeSuccess', function(event, current, previous) {
            $rootScope.curl = $location.absUrl();
        });
    }

})();
