(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('home', home);

    /* @ngInject */
    function home() {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            templateUrl: 'home.tpl',
            controller: homeController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                data: '<'
            }
        };
        return directive;
        /////////////////
        
        function link(scope, element, attrs) {
        }

    }

    /* @ngInject */
    function homeController($dimsum) {
        var vm = this;
        vm.poducts = [];
        for(var i = 0; i < 12; i++) {
            var picnum = 66 + Math.floor(Math.random() * 16);
            vm.poducts[i] = {
                title: $dimsum.sentence().split(/[\s\t\,\.\-]+/, 3 + Math.floor(Math.random() * 3)).join(' '),
                shortdesc: $dimsum.generate(1, { format: 'html' }),
                description: $dimsum.generate(Math.round(Math.random() * 5), { format: 'html' }),
                image: tdirurl + 'img/pp/' + picnum + '.jpg',
                srcset: tdirurl + 'img/pp/' + picnum + '.jpg 850w, '+
                        tdirurl + 'img/pp/' + picnum + '-100x122.jpg 100w, '+
                        tdirurl + 'img/pp/' + picnum + '-246x300.jpg 246w, '+
                        tdirurl + 'img/pp/' + picnum + '-300x366.jpg 300w, '+
                        tdirurl + 'img/pp/' + picnum + '-307x375.jpg 307w, '+
                        tdirurl + 'img/pp/' + picnum + '-420x512.jpg 420w, '+
                        tdirurl + 'img/pp/' + picnum + '-839x1024.jpg 839w',
                thumb: {
                    '100x122': tdirurl + 'img/pp/' + picnum + '-100x122.jpg',
                    '246x300': tdirurl + 'img/pp/' + picnum + '-246x300.jpg',
                    '300x366': tdirurl + 'img/pp/' + picnum + '-300x366.jpg',
                    '307x375': tdirurl + 'img/pp/' + picnum + '-307x375.jpg',
                    '420x512': tdirurl + 'img/pp/' + picnum + '-420x512.jpg',
                    '839x1024': tdirurl + 'img/pp/' + picnum + '-839x1024.jpg'
                },
                price: (Math.round((19.99 + Math.random() * 430) * 100) / 100),
                rating: (Math.round(Math.random() * 1000) / 100)
            };
            if((vm.poducts[i].price < 112.5) && Math.round(Math.random())) {
                vm.poducts[i].oldprice = Math.round((vm.poducts[i].price + Math.random() * 215) * 100) / 100;
            }
        }
// 'title': dimsum.sentence().split(/[\s\t\,\.\-]+/, 4 + Math.floor(Math.random() * 4)).join(' '),
// 'shortdesc': dimsum(1, { format: 'html' }),
// 'description': dimsum(Math.round(Math.random() * 5), { format: 'html' })
// 66.jpg
// 66-100x122.jpg
// 66-246x300.jpg
// 66-300x366.jpg
// 66-307x375.jpg
// 66-420x512.jpg
// 66-839x1024.jpg
    }

})();